
.wrapper {
	width: 1170px;
	margin: auto;
}
header {
	background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../../Assets/AImagebackground2.png);
	height: 100vh;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	position: relative;
}
.nav-area {
	float: right;
	list-style: none;
	margin-top: 30px;
}
.nav-area li {
	display: inline-block;
}
.nav-area li a {
	color: #fff;
	text-decoration: none;
	padding: 5px 20px;
	font-family: poppins;
	font-size: 16px;
	text-transform: uppercase;
}
.nav-area li a:hover {
	background: #fff;
	color: #333;
}
.logo {
	float: left;
}
.logo img {
	width: 100%;
	padding: 15px 0;
}
.welcome-text {
	position: absolute;
	width: 600px;
	height: 300px;
	margin: 20% 30%;
	text-align: center;
}
.welcome-text h2 {
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	font-size: 60px;
}
.welcome-text h2 span {
	color: #ed1e79;
}
.welcome-text a {
	border: 1px solid #fff;
	padding: 10px 25px;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 14px;
	margin-top: 20px;
	display: inline-block;
	color: #fff;
}
.welcome-text a:hover {
	background: #fff;
	color: #333;
}
/*resposive*/

@media (max-width:600px) {
	.wrapper {
		width: 100%;
	}
	.logo {
		float: none;
		width: 50%;
		text-align: center;
		margin: auto;
	}
	
	.nav-area {
		float: none;
		margin-top: 0;
	}
	.nav-area li a {
		padding: 5px;
		font-size: 11px;
	}
	.nav-area {
		text-align: center;
	}
	.welcome-text {
		width: 100%;
		height: auto;
		margin: 30% 0;
	}
	.welcome-text h2 {
		font-size: 30px;
	}
}


/* modal */
.modal_container {
  /* backdrop-filter: blur(16px) saturate(180%); */
  /* -webkit-backdrop-filter: blur(16px) saturate(180%); */
  /* background-color: rgba(17, 25, 40, 0.25); */
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.125);  
  padding: 38px;  
  filter: drop-shadow(0 30px 10px rgba(0,0,0,0.125));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  text-align: center;
  
}

.modal_close {
	position: absolute;
	right: 25px;
	border: 0;
	background-color: #ff0000;
	/* padding: 2px;
	margin: 2px; */
	/* cursor: pointer; */
}

.modal_wrapper {
  width: 100%;
  height: 100%;
  
}

.modal_header h2{
	color: #ed1e79;
}

.modal_header p{
	color: #676767;
}

.modal_banner-image {
  /* background-image: url(../../Assets/qrcode.png); */
  background-position: center;
  background-size: cover;
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(255,255,255, 0.255)
}

.qr-image{
	color: #d64668;
	height:100%;
	width: 100%;
}

.modal_name p{
  font-family: 'Righteous', sans-serif;
  color: #676767;
  font-size: 20px;
  font-weight: 500;
}

p {
  color: #fff;
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-size: 0.8rem;
  line-height: 150%;
  letter-spacing: 2px;
  /* text-transform: uppercase; */
}

.modal_button-wrapper{
  margin-top: 18px;
}

.modal_btn{
	background-color: #ed1e79;
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	border: 0;
	padding: 10px 30px;
	border-radius: 20px;
	cursor: pointer;
}

.nav_btn {
	background-color: #d64668;
	color: white;
	font-size: 15px;
	font-weight: 400;
	cursor: pointer;
	padding: 5px 5px;
	border-radius: 20px;
	border-color: #d64668;
}

/* .modal_btn {
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 12px;
  font-size: 0.8rem;  
  letter-spacing: 2px;  
  cursor: pointer;
} */

/* .modal_btn + .modal_btn {
  margin-left: 10px;
} */

/* .modal_outline {
  background: transparent;
  color: rgba(0, 212, 255, 0.9);
  border: 1px solid rgba(0, 212, 255, 0.6);
  transition: all .3s ease;
  
}

.modal_outline:hover{
  transform: scale(1.125);
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.9);
  transition: all .3s ease;  
} */

/* .modal_fill {
  background: rgba(0, 212, 255, 0.9);
  color: rgba(255,255,255,0.95);
  filter: drop-shadow(0);
  font-weight: bold;
  transition: all .3s ease; 
}

.modal_fill:hover{
  transform: scale(1.125);  
  border-color: rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0 10px 5px rgba(0,0,0,0.125));
  transition: all .3s ease;    
} */