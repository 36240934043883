.text-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container p {
  width: 50%;
}

.create_New-header{
text-align: center;
}

.create_New-header button{
  background: #ed1e79;
  padding: 1.25rem 5rem;
  outline: none;
  border: none;
  border-radius: 99px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 2.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.create_New {
  display: flex;
  align-items: center;
  justify-content: center;
}


.create_New-form{
  /* height: 520px; */
  width: 375px;
  background: #efefef;

  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  padding: 50px 35px;
}

form h1{
  color: #Ed1e79;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}


.create_New-label{
  color: #686868;
  display: block;
  /* margin-top: 30px; */
  font-size: 14px;
  font-weight: 500;
}
.create_New-input{
  display: block;
  height: 35px;
  width: 100%;
  background-color: #727272;
  border-radius: 13px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}
::placeholder{
  color: #e5e5e5;
}
.create_New-button{
  margin-top: 30px;
  width: 100%;
  background-color: #Ed1e79;
  color: #ffffff;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  border-radius: 15px;
  cursor: pointer;
}



.btn-wrap p {
  font-size: 2rem;
  margin: 3.25rem 0;
  color: #575757;
  font-weight: 400;
  line-height: 1.4;
}

.btn-new {
  background: #ed1e79;
  padding: 1.25rem 5rem;
  outline: none;
  border: none;
  border-radius: 99px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 2.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-new:hover {
  background: #ed1e78e7;
}

.btn-new:active {
  transform: translateY(2px);
}


.signup_container{
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}
.signup_container .signup_title{
  font-size: 25px;
  font-weight: 500;
  position: relative;
}
.signup_container .signup_title::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
.signup_content form .user-details{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
form .user-details .input-box{
  position: relative;
  margin-bottom: 15px;
  width: calc(100% / 2 - 20px);
}
form .input-box span.details{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}
.user-details .input-box input{
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user-details .input-box input:focus,
.user-details .input-box input:valid{
  border-color: #9b59b6;
}

 form .button{
   height: 45px;
   margin: 35px 0
 }
 form .button input{
   height: 100%;
   width: 100%;
   border-radius: 5px;
   border: none;
   color: #fff;
   font-size: 18px;
   font-weight: 500;
   letter-spacing: 1px;
   cursor: pointer;
   transition: all 0.3s ease;
   background: linear-gradient(135deg, #71b7e6, #9b59b6);
 }
 form .button input:hover{
  /* transform: scale(0.99); */
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
  }
 @media(max-width: 584px){
 .signup_container{
  max-width: 100%;
}
form .user-details .input-box{
    margin-bottom: 15px;
    width: 100%;
  }

  .signup_content form .user-details{
    max-height: 300px;
    overflow-y: scroll;
  }
  .user-details::-webkit-scrollbar{
    width: 5px;
  }
  }

