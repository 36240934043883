.wrapper-1-info{
    width:100%;
    height:100vh;
    display: flex;
  flex-direction: column;
  }
  .wrapper-2-info{
    padding :25px;
    text-align:center;
  }
  h1{
      font-family: 'Kaushan Script', cursive;
    font-size:3em;
    letter-spacing:3px;
    color:#ff4949 ;
    margin:0;
    margin-bottom:2rem;
  }
  .wrapper-2-info p{
    margin:0;
    font-size:1.3em;
    color:#aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }

  .wrapper-3-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 8px;
    font-size: 1em;
    color:#302e2e;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
    border-radius: 15px;
    background-color: #e3e5ff;
    box-shadow:inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  }

  .wrapper-3-info-p p{
    text-align: center;
    color: #302e2e;
    font-size: 16px;
  }

  .footer-like{
    margin-top: auto; 
    background:#D7E6FE;
    padding:6px;
    text-align:center;
  }
  .footer-like p{
    margin:0;
    padding:4px;
    color:#ed1e79;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }
  .footer-like p a{
    text-decoration:none;
    color:#ed1e79;
    font-weight:600;
  }
  
  @media (min-width:360px){
    h1{
      font-size:3em;
    }
    .go-home{
      margin-bottom:20px;
    }
  }
  
  @media (min-width:600px){
    .content{
    max-width:1000px;
    margin:0 auto;
  }
    .wrapper-1-info{
    height: initial;
    max-width:620px;
    margin:0 auto;
    margin-top:50px;
    /* box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2); */
  }
    
  }