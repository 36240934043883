.wrapper-1{
    width:100%;
    height:100vh;
    display: flex;
  flex-direction: column;
  justify-content: center;
  }
  .wrapper-2{
    padding :30px;
    text-align:center;
  }
  h1{
      font-family: 'Kaushan Script', cursive;
    font-size:3em;
    letter-spacing:3px;
    color:#ed1e79 ;
    margin:0;
    margin-bottom:3rem;
  }
  .wrapper-2 p{
    margin:0;
    font-size:1.3em;
    color:#aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }

  .wrapper-3{
    margin: 10px;
    padding: 5px;
    font-size: 1em;
    color:#302e2e;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
    border-radius: 10px;
    background-color: #aaa;
  }

  .footer-like{
    margin-top: auto; 
    background:#D7E6FE;
    padding:6px;
    text-align:center;
  }
  .footer-like p{
    margin:0;
    padding:4px;
    color:#ed1e79;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }
  .footer-like p a{
    text-decoration:none;
    color:#ed1e79;
    font-weight:600;
  }
  
  @media (min-width:360px){
    h1{
      font-size:3em;
    }
    .go-home{
      margin-bottom:20px;
    }
  }
  
  @media (min-width:600px){
    .content{
    max-width:1000px;
    margin:0 auto;
  }
    .wrapper-1{
    height: initial;
    max-width:620px;
    margin:0 auto;
    margin-top:50px;
    /* box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2); */
  }
    
  }