.forgot-nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .company-header {
    font-weight: 300;
    font-size: 22px;
    color: #d6dcdd;
    letter-spacing: 4px;
  }
  
  .company-header-2 {
    font-size: 22px;
    font-weight: bold;
  }
  
  .forgot_nav-signup {
    margin-left: 0.5rem;
    font-size: 14px;
    font-weight: 600;
    color: #01bf71;
    text-decoration: none;
  }
  .forgot_nav-signup:hover {
    color: #01bf71;
  }
  
  .forgot__main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
    min-height: 80vh;
  }
  
  .forgot__title {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #d6dcdd;
    letter-spacing: 1px;
  }
  
  .forgot-hr {
    border: 2px solid #01bf71;
    padding: 0 1rem;
    color: #01bf71;
    opacity: 100;
    border-radius: 20px;
    width: 15%;
    height: 5px;
    margin: 0 !important;
  }
  
  .forgot__border {
    border: 1px solid #01bf71;
    padding: 0 1rem;
    color: #01bf71;
    opacity: 100;
    border-radius: 20px;
    width: 15%;
    height: 5px;
    margin: 10px !important;
  }
  
  .forgot__acc2 {
    display: none;
  }
  .forgot-card-container {
    box-shadow: -4px -4px 10px #2b3336, 4px 4px 10px #1d2122;
    padding: 5rem 0;
    border-radius: 20px;
    margin: 2rem;
    max-width: 320px;
    height: auto;
    /* background: #fff; */
  }
  .forgot__form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .forgot__form {
    position: relative;
    width: 20rem;
    height: 3.2rem;
    padding: 10px;
    margin: 10px;
  }
  
  .forgot__form__input {
    position: absolute;
    top: 4px;
    left: 17px;
    width: 88%;
    height: 10%;
    border: 1px solid #5f5f5f;
    border-radius: 15px;
    font-size: 14px;
    color: #d6dcdd;
    outline: none;
    padding: 18px;
    background: none;
    display: flex;
    justify-content: center;
    -webkit-transition: 1s;
    transition: 0.5s;
  }
  
  .forgot__form__input::placeholder {
    color: #33383a;
    font-size: 12px;
  }
  
  .forgot__form__input:hover {
    border-color: #858585;
  }
  .forgot__form__input:focus {
    border-color: #858585;
  }
  .forgot__form__label {
    position: absolute;
    left: 35px;
    top: 12px;
    padding: 0;
    color: #a7aaab;
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
    background-color: #252b2d;
    font-size: 14px !important;
    pointer-events: none !important;
  }
  
  .forgot__acc2 {
    display: none;
  }
  
  /*
    1. When the input is in the focus state
    reduce the size of the label and move upwards
    
    2. Keep label state when content is in input field
    */
  .forgot__form__input:focus ~ .forgot__form__label,
  .forgot__form__input:not(:placeholder-shown).forgot__form__input:not(:focus)
    ~ .forgot__form__label {
    top: -0.4rem;
    font-size: 12px !important;
    left: 2.8rem;
  }
  
  .forgot_err {
    color: #ff4a4a;
    margin-left: 10px;
    margin-top: 34px;
    font-size: 10px;
  }
  
  .forgot__entermail {
    font-size: 16px;
    color: #ed1e79;
    margin: 10px 0px !important;
    text-align: center;
    padding: 4px 33px;
    letter-spacing: -0.5px;
  }
  
  .forgot__button {
    margin-top: 10px;
    border: none;
    background-color: #01bf71;
    color: #eeeeee;
    padding: 5px 90px;
    border-radius: 20px;
    font-size: 14px;
  }
  
  .verify__button {
    width: 85%;
    margin-top: 10px;
    border: none;
    background-color: #01bf71;
    color: #eeeeee;
    padding: 5px 90px;
    border-radius: 20px;
    font-size: 14px;
    margin-left: 25px;
  }
  
  .resetpassword__button {
    margin-top: 10px;
    border: none;
    background-color: #01bf71;
    color: #eeeeee;
    padding: 5px 70px;
    border-radius: 20px;
    font-size: 14px;
  }
  
  .forgot__login {
    margin: 20px 0;
    color: #a7aaab;
    text-align: center;
    cursor: pointer;
  }
  
  .user-otp-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .signup__otp2 {
    color: #01bf71;
  }
  .resend-button {
    display: flex;
    justify-content: flex-end;
  }
  .resend-button button {
    border: 0;
    background-color: #252b2d;
    color: #a7aaab;
  }
  .otp-input-root input {
    color: #a7aaab !important;
  }
  
  @media only screen and (min-width: 450px) {
    .forgot_nav-signup {
      margin-left: 0.5rem;
      font-size: 18px;
      font-weight: 600;
      color: #01bf71;
      text-decoration: none;
    }
    .forgot_nav-signup:hover {
      color: #01bf71;
    }
  
    .forgot__main {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
    }
  
    .forgot-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
    }
  
    .forgot__title {
      font-size: 25px;
      font-weight: bold;
      text-align: center;
      color: #d6dcdd;
      letter-spacing: 1px;
    }
  
    .forgot__border {
      border: 1px solid #01bf71;
      padding: 0 1rem;
      color: #01bf71;
      opacity: 100;
      border-radius: 20px;
      width: 15%;
      height: 5px;
      margin-bottom: 1rem;
    }
  
  
    .forgot__form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .forgot__form-container .input-control input {
      color: #000000 !important;
    }
  
    .forgot__form {
      position: relative;
      width: 20rem;
      height: 3.2rem;
      padding: 10px;
      margin: 10px;
    }
  
    .forgot__acc {
      display: flex;
      align-items: center;
    }
  
    .forgot__acc2 {
      display: block;
      color: #a7aaab;
    }
  
    .forgot_err {
      color: #ff4a4a;
      margin-left: 10px;
      margin-top: 34px;
      font-size: 10px;
    }
  
    .forgot__entermail {
      font-size: 16px;
      color: #ed1e79;
      margin: 10px 0px !important;
      text-align: center;
      padding: 4px 33px;
      letter-spacing: -0.5px;
    }
  
    .forgot__button {
      padding: 5px 97px;
    }
  
    .verify__button {
      padding: 5px 97px;
    }
  
    .resetpassword__button {
      margin-top: 10px;
      border: none;
      background-color: #01bf71;
      color: #eeeeee;
      padding: 5px 75px;
      border-radius: 20px;
      font-size: 16px;
    }
  
    .forgot__login {
      margin: 20px 0;
      color: #a7aaab;
      text-align: center;
      cursor: pointer;
    }
  
    .forgot_icon {
      margin-right: 10px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .forgot-nav-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      margin-bottom: 0;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  
    .company-header {
      font-weight: 300;
      font-size: 32px;
      color: #d6dcdd;
      letter-spacing: 4px;
    }
  
    .company-header-2 {
      font-size: 32px;
      font-weight: bold;
    }
  
    .forgot_nav-signup {
      margin-left: 0.5rem;
      font-size: 18px;
      font-weight: 600;
      color: #01bf71;
      text-decoration: none;
    }
    .forgot_nav-signup:hover {
      color: #01bf71;
    }
  
    .forgot__main {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
    }
  
   
  

    .forgot__button {
      padding: 5px 100px;
      border-radius: 20px;
      font-size: 16px;
    }
    .verify__button {
      width: 85%;
      padding: 5px 100px;
      border-radius: 20px;
      font-size: 16px;
      margin-left: 25px;
    }
  
  
    .forgot__login {
      margin: 20px 0;
      color: #a7aaab;
      text-align: center;
      cursor: pointer;
    }
  }

  .verify-form__button{
    margin: 2rem;
  }
  
  .verify-form__button button {
    padding: 10px 60px;
    background: transparent;
    border: 0;
    color: #fff;
    border-radius: 20px;
    background: #ed1e79;
  }

  .verifyotp-main{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  