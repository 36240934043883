
section {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../../Assets/AImagebackground2.png);
  height: 100vh;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.logo-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.userdetail-form *{
font-family: 'Poppins',sans-serif;
color: #ffffff;
letter-spacing: 0.5px;
outline: none;
border: none;
}
h1{
color: #Ed1e79;
font-size: 32px;
font-weight: 500;
line-height: 42px;
text-align: center;
}

h3{
color: #727272;
text-align: center;
padding: 10px 40px;
}
h3 span{
color: #ff0000;
}

.userdetail-label{
color: #686868;
display: block;
font-size: 14px;
font-weight: 500;
padding-top: 15px;
}


.text-color {
color: black;
}

.selfie-container{
display: flex;
align-items: center;
text-align: center;
padding-top: 10px;
}

.camera_img{
margin-right: 5px !important;
}

.selfie-btn{
display: flex;
align-items: center;
background-color: #727272;
color: #e5e5e5;
border: 0;
border-radius: 15px;
padding: 5px 15px;
}



.userdetail-file-input{
display: block;
height: 30px;
width: 100%;
border-radius: 13px;
padding: 0 10px;
margin-top: 5px;
font-size: 14px;
font-weight: 300;
}

.userdetail-input{
display: block;
height: 35px;
width: 100%;
background-color: #727272;
border-radius: 13px;
padding: 0 10px;
margin-top: 8px;
font-size: 14px;
font-weight: 300;
}
::placeholder{
color: #e5e5e5;
}
.userdetail-button{
margin-top: 30px;
width: 100%;
background-color: #Ed1e79;
color: #ffffff;
padding: 10px 0;
font-size: 16px;
font-weight: 400;
border-radius: 15px;
cursor: pointer;
}

.background-style{
  background-image: url('../../Assets/maze-logo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background-sheet{
  padding: 60% 0%;
  position: relative;
  top: 172px;
}

.userDetail-form-container {
height: 100%;
width: 100%;
padding: 20px;
background-image: url('../../Assets/cropimage.jpg');
background-size: cover;
background-repeat:no-repeat;
background-position: 50%;
background-position-y:-25px ;

}


.PhoneInputInput{
background-color: #727272;
}


.uniqueImage{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

@media only screen and (max-width: 800px) {

/* .userDetail-form-container {
  height: 70vh;
  width: 90vw;
  padding: 20px;
} */



}

@media only screen and (max-width: 500px) {



/* .userDetail-form-container {
  height: 70vh;
  width: 85vw;
  padding: 20px;
} */



}


