*,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Toast */

.Toastify__toast {
  padding: 0 15px !important;
}
.Toastify__toast--error {
  border-radius: 50px !important;
  background: #252B2D !important;
  box-shadow: 0px 3px 6px #00000066;
}
.Toastify__toast--error .Toastify__toast-body {
  color: red !important;
}
.Toastify__toast--error .Toastify__toast-body .Toastify__toast-icon svg {
  fill: red !important;
}
.Toastify__close-button--light {
  color: #000;
  position: absolute;
  top: 19px;
  right: 15px;
  opacity: 0.3;
}
.Toastify__toast--error::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
/* SUCCESS */
.Toastify__toast--success {
  border-radius: 50px !important;
  background: #252B2D !important;
  box-shadow: 0px 3px 6px #00000066;
}
.Toastify__toast--success .Toastify__toast-body {
  color: #ed1e79 !important;
}

.Toastify__toast--success .Toastify__toast-body .Toastify__toast-icon svg {
  fill: #ed1e79 !important;
}
.Toastify__toast--success::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
/* WARNING */
.Toastify__toast--warning {
  border-radius: 50px !important;
  background: #252B2D !important;
  box-shadow: 0px 3px 6px #00000066;
}
.Toastify__toast--warning .Toastify__toast-body {
  color: #F1C40F !important;
}
.Toastify__close-button--light {
  color: #000;
  position: absolute;
  top: 19px;
  right: 15px;
  opacity: 0.3;
}
.Toastify__toast--warning::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}